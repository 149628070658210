<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-form
          ref="form"
          lazy-validation
          class="app-form"
          @submit.prevent="onSubmit"
        >
          <v-card class="app-card">
            <v-card-title class="app-card-title">
              <h4 class="text">
                <span>
                  {{
                    currentRoute === "dashboard-tipo-pedido-novo"
                      ? "Novo"
                      : "Editar"
                  }}
                  Tipo Pedido
                </span>
              </h4>
            </v-card-title>
            <v-card-text class="app-card-content">
              <v-row>
                <v-col
                  :lg="4"
                  :md="4"
                  :sm="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.name"
                    outlined
                    dense
                    label="Nome *"
                    placeholder="Digite o nome"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :lg="4"
                  :md="4"
                  :sm="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.key"
                    outlined
                    dense
                    label="Key *"
                    placeholder="Ex:. CADASTRO_EMPRESA"
                    :rules="[rules.required]"
                    :disabled="currentRoute !== `dashboard-tipo-pedido-novo`"
                  />
                </v-col>
                <v-col
                  :lg="4"
                  :md="4"
                  :sm="4"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="form.value"
                    outlined
                    dense
                    label="Valor *"
                    placeholder="Ex:. 10.90"
                    :rules="[rules.required]"
                    type="number"
                    min="0"
                  />
                </v-col>
              </v-row>
              <v-form
                ref="formTipoDocumento"
                lazy-validation
                class="app-form"
              >
                <v-row>
                  <v-col
                    :lg="12"
                    :md="12"
                    :sm="12"
                    class="pt-9 pb-0"
                  >
                    <h4 class="title">
                      Tipos de Documento
                    </h4>
                  </v-col>
                  <v-col
                    :lg="8"
                    :md="8"
                    :sm="8"
                    class="pt-0 pb-0"
                  >
                    <v-autocomplete
                      v-model="formTipoDocumento.tipoDocumentoObj"
                      label="Tipos de Documentos"
                      :items="tiposDocumentos"
                      item-text="name"
                      return-object
                      outlined
                      dense
                      :rules="[rules.required]"
                      :loading="loadingTiposDocumentos"
                    />
                  </v-col>
                  <v-col
                    :lg="2"
                    :md="2"
                    :sm="2"
                    class="pt-0 pb-0"
                  >
                    <v-text-field
                      v-model="formTipoDocumento.tipoDocumentoOrdem"
                      outlined
                      dense
                      label="Ordem *"
                      placeholder="Ex:. 1"
                      :rules="[rules.required]"
                      type="number"
                      min="0"
                    />
                  </v-col>
                  <v-col
                    :lg="2"
                    :md="2"
                    :sm="2"
                    class="pt-0 pb-0"
                  >
                    <v-btn
                      depressed
                      block
                      color="primary"
                      type="button"
                      @click.prevent="addTipoDocumento"
                    >
                      <v-icon>add</v-icon>Adicionar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider />
              <v-list two-line>
                <v-list-item-group>
                  <template v-for="(item, index) in form.typeDocumentsTypeSolicitations">
                    <v-list-item :key="index+form.typeDocumentsTypeSolicitations.length">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.typeDocument.name" />
                          <!-- <v-list-item-subtitle
                            class="text--primary"
                            v-text="item.typeDocument.description"
                          ></v-list-item-subtitle>-->
                          <v-list-item-subtitle class="text--primary">
                            <v-text-field
                              v-model="item.ordering"
                              class="mt-1"
                              outlined
                              dense
                              label="Ordem *"
                              placeholder="Ex:. 1"
                              type="number"
                              min="0"
                              style="width: 100px;"
                            />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="red lighten-1"
                            @click.prevent="deleteTipoDocumento(item)"
                          >
                            delete
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < form.typeDocumentsTypeSolicitations.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
              <!-- <pre>{{form}}</pre> -->
            </v-card-text>
            <v-divider />
            <v-card-actions class="app-card-actions">
              <span class="app-form__warning">
                Atenção: Campos que possuem o
                <em>*</em> são obrigatórios
              </span>
              <v-spacer />
              <v-btn
                depressed
                outlined
                color="error"
                @click="$router.back()"
              >
                Voltar
              </v-btn>
              <v-btn
                depressed
                color="success"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
            <section
              v-if="detail.isLoading"
              class="loading"
            >
              <v-progress-circular
                indeterminate
                color="success"
              />
              <span>Enviando informações ...</span>
            </section>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import TipoDocumento from '@/services/tipo-documento.service';

  export default {
    data: () => ({
      currentRoute: null,
      form: {
        name: null,
        value: null,
        key: null,
        typeDocumentsTypeSolicitations: []
      },
      formTipoDocumento: {
        tipoDocumentoObj: null,
        tipoDocumentoOrdem: 0
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido'
      },
      maskMoney: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      tiposDocumentos: [],
      loadingTiposDocumentos: false
    }),
    computed: {
      ...mapState('tipoPedido', ['detail'])
    },
    mounted() {
      this.iniData();
    },
    destroyed() {
      this.clearResource();
    },
    methods: {
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          if (this.currentRoute === 'dashboard-tipo-pedido-novo') {
            this.create({ ...this.form });
          } else {
            this.update(this.form);
          }
        }
      },
      async iniData() {
        const {
          name,
          params: { id }
        } = this.$route;

        this.currentRoute = name;

        this.loadTiposDocumentos();

        if (name === 'dashboard-tipo-pedido-editar') {
          await this.getTipoPedido(id);
          this.form = { ...this.detail.data };
        }
      },
      loadTiposDocumentos() {
        this.loadingTiposDocumentos = true;
        const query = {
          input: {
            name: null,
            typeSolicitationId: null
          },
          inputPage: {
            page: 1,
            size: 1000,
            order: 'id',
            descingOrder: false
          }
        };
        TipoDocumento.page(query).subscribe(
          ({ items }) => {
            this.loadingTiposDocumentos = false;
            this.tiposDocumentos = items;
          },
          err => {
            this.loadingTiposDocumentos = false;
          }
        );
      },
      addTipoDocumento() {
        const isValid = this.$refs.formTipoDocumento.validate();

        if (isValid) {
          const { tipoDocumentoObj, tipoDocumentoOrdem } = this.formTipoDocumento;
          const tipo = {
            typeDocument: tipoDocumentoObj,
            ordering: tipoDocumentoOrdem
          };
          this.form.typeDocumentsTypeSolicitations = [
            ...this.form.typeDocumentsTypeSolicitations,
            tipo
          ];
        }
      },
      deleteTipoDocumento(item) {
        const index = this.form.typeDocumentsTypeSolicitations.indexOf(item);
        this.form.typeDocumentsTypeSolicitations.splice(index, 1);
      },
      ...mapActions('tipoPedido', { create: 'CREATE' }),
      ...mapActions('tipoPedido', { update: 'UPDATE' }),
      ...mapActions('tipoPedido', { getTipoPedido: 'SHOW' }),
      ...mapActions('tipoPedido', { clearResource: 'CLEAR_DETAIL' })
    },
  };
</script>

<style lang="scss" scoped>
.title {
  margin: 0px;
  font-size: 15px !important;
  font-weight: bold !important;
}
</style>
